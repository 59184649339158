import { memo } from 'react';
import {
  useTranslations,
  Typography,
  ArrowUpIcon,
  Grid,
  AR1,
  AG3,
  S5,
  usePasswordStrength,
  ArrowDownIcon,
  Divider,
  TypographyWithHelp,
  S6,
} from '@uniqkey-frontend/shared-app';
import { Password2FaStatus } from '@uniqkey-backend-organization-web/api-client';
import { TWO_FA_FILTER_TRANSLATION_KEYS } from '../../../../constants';

interface ISecurityScoreStatisticsWidgetProps {
  strength: number;
  password2FaStatus?: Password2FaStatus;
  hasReusedPassword?: boolean;
}

const TITLE_TYPOGRAPHY_PROPS = { color: S6 };
const DIVIDER_SX = { marginX: -3 };

const SecurityScoreStatisticsWidget = (props: ISecurityScoreStatisticsWidgetProps) => {
  const {
    strength, password2FaStatus, hasReusedPassword,
  } = props;
  const { t } = useTranslations();
  const { color } = usePasswordStrength({ strength });
  const isPassword2FAOff = password2FaStatus === Password2FaStatus.Off;

  return (
    <>
      <Grid item>
        <Divider sx={DIVIDER_SX} />
      </Grid>
      <Grid item container justifyContent="space-between" py={1.5}>
        <Grid item>
          <Typography variant="caption" color={S5} alignContent="center">
            {t('loginDetailsSecurityScoreWidget.passwordStrength')}
            :&nbsp;
            <Typography variant="caption" component="span" color={color}>
              {strength}
              %
            </Typography>
          </Typography>
        </Grid>
        <Grid item>
          <TypographyWithHelp
            TypographyProps={TITLE_TYPOGRAPHY_PROPS}
            helperText={t('loginDetailsSecurityScoreWidget.passwordStrength.tooltip')}
          >
            {strength ? (
              <>
                <ArrowUpIcon color={AG3} />
                <Typography color={AG3} component="span">+</Typography>
                <Typography variant="caption" color={S5}>
                  {Math.round(strength * 0.7)}
                  %
                </Typography>
              </>
            ) : (
              <Typography variant="caption" color={S5} alignContent="center">0%</Typography>
            )}
          </TypographyWithHelp>
        </Grid>
      </Grid>
      <Grid item>
        <Divider sx={DIVIDER_SX} />
      </Grid>
      <Grid item container justifyContent="space-between" py={1.5}>
        <Grid item>
          <Typography variant="caption" color={S5} alignContent="center">
            {t('loginDetailsSecurityScoreWidget.2FALayer')}
            :&nbsp;
            <Typography
              variant="caption"
              component="span"
              color={isPassword2FAOff ? AR1 : AG3}
            >
              {t(TWO_FA_FILTER_TRANSLATION_KEYS[password2FaStatus!])}
            </Typography>
          </Typography>
        </Grid>
        <Grid item>
          <TypographyWithHelp
            TypographyProps={TITLE_TYPOGRAPHY_PROPS}
            helperText={t('loginDetailsSecurityScoreWidget.2FALayer.tooltip')}
          >
            {isPassword2FAOff ? (
              <Typography variant="caption" color={S5} alignContent="center">0%</Typography>
            ) : (
              <>
                <ArrowUpIcon color={AG3} />
                <Typography color={AG3} component="span">+</Typography>
                <Typography variant="caption" color={S5} alignContent="center">30%</Typography>
              </>
            )}
          </TypographyWithHelp>
        </Grid>
      </Grid>
      <Grid item>
        <Divider sx={DIVIDER_SX} />
      </Grid>
      <Grid item container justifyContent="space-between" pt={1.5}>
        <Grid item>
          <Typography variant="caption" color={S5} alignContent="center">
            {t('loginDetailsSecurityScoreWidget.hasReusedPassword')}
            :&nbsp;
            <Typography
              variant="caption"
              component="span"
              color={hasReusedPassword ? AR1 : AG3}
            >
              {t(hasReusedPassword ? 'common.yes' : 'common.no')}
            </Typography>
          </Typography>
        </Grid>
        <Grid item>
          <TypographyWithHelp
            TypographyProps={TITLE_TYPOGRAPHY_PROPS}
            helperText={t('loginDetailsSecurityScoreWidget.hasReusedPassword.tooltip')}
          >
            {hasReusedPassword && (
            <>
              <ArrowDownIcon color={AR1} />
              <Typography color={AR1} component="span">-</Typography>
              <Typography variant="caption" color={S5} alignContent="center">40%</Typography>
            </>
            )}
          </TypographyWithHelp>
        </Grid>
      </Grid>
    </>
  );
};

export default memo(SecurityScoreStatisticsWidget);
